import React, { FC, useState, useRef } from 'react';
import classNames from 'utils/class-names';
import { useGetPlaylistsPopularQuery } from '../../../redux/api/playlists-popular';
import './PopularPlaylists.scss';
import FireIcon from 'assets/icons/fire.png';
import CoverPlaylist from './CoverPlaylist';
import { Button } from '../app-button';
import { LinkButton } from '../link-button';
import useTranslation from 'hooks/useTranslations';
import { usePush } from 'hooks/usePush';

type PopularPlaylistsProps = {
  showFireIcon?: boolean;
  title: string;
  textWithGradient?: boolean;
  isSimilarCategories?: boolean;
};

export const PopularPlaylists: FC<PopularPlaylistsProps> = ({
  showFireIcon,
  title,
  textWithGradient,
  isSimilarCategories
}) => {
  const push = usePush();
  const { data = [] } = useGetPlaylistsPopularQuery();
  const className = classNames({
    'popular-playlists': true,
    'popular-playlists_with-gradient': !!textWithGradient
  });
  const [isOpenPlaylists, setIsOpenPlaylists] = useState(false);

  const headerClassName = classNames({
    'popular-playlists__header': !isSimilarCategories,
    'similar-categories__title': isSimilarCategories
  });

  const bodyClassName = classNames({
    'popular-playlists__body': !isSimilarCategories,
    'popular-playlists__body-active': !isSimilarCategories && isOpenPlaylists,
    'similar-categories': isSimilarCategories
  });

  const popularPlaylistRef = useRef<HTMLDivElement | null>(null);
  const scrollToPopularPlaylists = () => {
    if (popularPlaylistRef.current) {
      const elementPosition = popularPlaylistRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - 127;

      setTimeout(() => {
        if (offsetPosition < window.scrollY) {
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }, 20);
    }
  };
  const translate = useTranslation();

  return (
    <div className={className}>
      <div className={headerClassName} ref={popularPlaylistRef}>
        {title}
        {showFireIcon && (
          <img
            src={FireIcon}
            className='popular-playlists__header-icon'
            alt='fire-icon'
            width={18}
            height={18}
          />
        )}
      </div>
      <div className={bodyClassName}>
        {data.map((item) => (
          <div className={'popular-playlists__item'} key={item.routing}>
            <CoverPlaylist playlist={item} isSimilarCategories={isSimilarCategories} />
          </div>
        ))}
      </div>
      {!isSimilarCategories && (
        <>
          <LinkButton
            className='popular-playlists__link'
            onClick={() => push('/render/playlists')}
          >
            {translate("All playlists")}
          </LinkButton>
          <div
            className={
              isOpenPlaylists
                ? 'popular-playlists__button-wrapper-active'
                : 'popular-playlists__button-wrapper'
            }
          >
            <Button
              className='popular-playlists__button'
              onClick={() => {
                setIsOpenPlaylists(!isOpenPlaylists);
                if (isOpenPlaylists) {
                  scrollToPopularPlaylists();
                }
              }}
            >
              {!isOpenPlaylists ? translate('Open all playlists') : translate('Close playlists')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
