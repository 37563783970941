import { api } from 'api';
import { openInNewTab } from 'utils/render-utils';
import { hashCode } from 'utils/hash';

type DownloadTrackParams = {
  sessionId: string;
  licenseId: string;
  generateLicense?: boolean;
};

export async function downloadTrack({ sessionId, licenseId }: DownloadTrackParams) {
  const track = await api.generateTrackInfo({
    id: sessionId,
    licenseId,
    generateLicense: false
  });

  if (track &&
    track.purchases &&
    track.purchases[licenseId] &&
    track.purchases[licenseId].track
  ) {
    setTimeout(() => {
      openInNewTab(track.purchases[licenseId].track);
    });
  }
}

export async function downloadLicense({
  sessionId,
  licenseId,
  generateLicense
}: DownloadTrackParams) {
  try {
    const track = await api.generateTrackInfo({
      id: sessionId,
      licenseId,
      generateLicense
    });

    if (
      track &&
      track.purchases &&
      track.purchases[licenseId] &&
      track.purchases[licenseId].license
    ) {
      setTimeout(() => {
        openInNewTab(track.purchases[licenseId].license);
      });
    } else {
      throw new Error('License error');
    }
  } catch (e) {
    throw new Error('License error');
  }
}

function downloadURI(uri, name = '') {
  let link = document.createElement("a");
  link.download = name; // <- name instead of 'name'
  link.href = uri;
  link.click();
  link.remove();
}

const COLORS = [
  '#8086FF',
  '#BF80FF',
  '#80D1FF',
  '#FFAE80',
  '#FF8080',
  '#80FFFF',
  // '#C7FF80',
  // '#FCFF80',
  // '#85FFA7',
  '#85C4FF'
];

export function getColorBySessionId(sessionId: string) {
  return COLORS[hashCode(sessionId) % COLORS.length];
}
