import React from 'react';
import { useAppSelector, useFetchGeneratedTrackList } from 'hooks';
import classNames from 'utils/class-names';
import './track-count.scss';

type TrackCountProps = {
  count: number;
  isFlicker: boolean;
  isHidden: boolean;
  onClick?: () => void;
};


function TrackCountComponent({ count, isFlicker, isHidden }: TrackCountProps) {
  const className = classNames({
    'track-count': true,
    'track-count__long': count > 9,
    'track-count_flickering': isFlicker
  });

  if (isHidden || count === 0) {
    return null;
  }

  return <div className={className}>{count}</div>;
}

export function TrackCount() {
  const generatingTrackIds = useAppSelector((state) => state.generator.generatedTracks);
  const { data, isLoading: isTracksLoading, isTrackGenerating } = useFetchGeneratedTrackList();

  return (
    <TrackCountComponent
      count={generatingTrackIds.length}
      isFlicker={isTrackGenerating}
      isHidden={data.length === 0 && isTracksLoading}
    />
  );
}
