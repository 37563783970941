import React from 'react';
import classNames from 'utils/class-names';
import './gradient-text.scss';

type GradientTextProps = {
  className?: string;
  children: React.ReactNode;
  gradientType?: 'blue' | 'green' | 'modern';
  onClick?: () => void;
};

export function GradientText({ className: propClassName, children, gradientType = 'blue', onClick }: GradientTextProps) {
  const className = classNames({
    'gradient-text': true,
    [`gradient-text_${gradientType}`]: true,
    [propClassName]: !!propClassName
  });

  return <span className={className} onClick={onClick} role={onClick ? 'button': undefined}>{children}</span>;
}
